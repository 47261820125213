<template>
    <div class="review">
        <HeadingComponent
            title="Please confirm </br>your package"
            subtitle="You can name your package"
        />

        <ApplicationSelector
            :is-review-page="true"
            :light="true"
            @onApplicationSelected="onApplicationSelected"
            class="mt-8 mb-1"
        />

        <template>
            <div class="review__cards-container">
                <v-card elevation="0" class="card">
                    <h4>
                        Services
                    </h4>
                    <div class="card__content">
                        <div class="review__items">
                            <div class="review__item" v-for="service in selectedServices" :key="service.item">
                                <span>{{ service.name }}</span>
                                <v-btn small icon ><v-icon color="secondary" small>mdi-trash-can-outline</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </v-card>
            </div>
        </template>
    </div>
</template>

<script>
import HeadingComponent from '@/components/HeadingComponent';
import ApplicationSelector from '@/components/ApplicationSelector';

export default {
    name: 'Review',
    components: {
        HeadingComponent,
        ApplicationSelector,
    },
    data: () => {
        return {
            selectedServices: [
                { name: 'Criminal Background', item: 'criminal-background' },
                { name: 'Collection of Electronic Signatures/Initials', item: 'collection-of-electronic' },
                { name: 'Basic Document Collection', item: 'basic-document-collection' },
                { name: 'Collection of Application Fees', item: 'collection-application-fees' },
                { name: 'Credit Report with SSN Validation', item: 'credit-report-ssn' },
                { name: 'Nationwide Evictions, Suits, Liens, and Judgements', item: 'nationwide-evictions' },
                { name: 'Standard Reference Verifications', item: 'standard-reference-verifications' },
                { name: 'Expedite application processing', item: 'expedite-application-processing' },
            ],
        }
    },
    methods: {
        onApplicationSelected(value) {
            alert(`You selected ${value}`);
        },
    },
};
</script>

<style scoped lang="scss">
@import './src/scss/abstracts/variables';
@import './src/scss/abstracts/functions';

.review {
    &__item {
        display: flex;
        justify-content: space-between;
        color: $text-color;
        border-bottom: 1px solid #d5d5d5;
        padding: 4px 0 4px;
        span {
            font-size: 14px;
            line-height: 2;
        }
    }
}

</style>
